import React from "react"

import Layout from "../components/layout/layout"
import Header from "../components/layout/header"
import Footer from "../components/layout/footer"

import { RootProvider } from "../components/root-provider"
import SEO from "../components/seo"
import IOsBanner from "../components/iosbanner/iosbanner"
import RyderCup from "../components/ryder-cup/ryder-cup"

const IndexPage = () => (
  <RootProvider>
    <div className="page-wrap">
      <SEO
        title="Fast Golf Scores | Ryder Cup Leaderboard"
        description="delivered in milliseconds"
        euro={true}
      />
      <Header />
      <Layout>
        <RyderCup />
      </Layout>
      <IOsBanner />
      <Footer />
    </div>
  </RootProvider>
)

export default IndexPage
