// extracted by mini-css-extract-plugin
export var matchScoreLeft = "leaderboard-match-team-module--matchScoreLeft--49ec3";
export var matchScoreRight = "leaderboard-match-team-module--matchScoreRight--140c9";
export var matchTable = "leaderboard-match-team-module--matchTable--9f2fe";
export var matchWinner = "leaderboard-match-team-module--matchWinner--69136";
export var matchWrap = "leaderboard-match-team-module--matchWrap--e7c6c";
export var matchWrapLeftActive = "leaderboard-match-team-module--matchWrapLeftActive--006c0";
export var matchWrapRightActive = "leaderboard-match-team-module--matchWrapRightActive--d8fc5";
export var teamScoreHeader = "leaderboard-match-team-module--teamScoreHeader--6c621";
export var teamScoreHeaderProjected = "leaderboard-match-team-module--teamScoreHeaderProjected--954a4";
export var teamScoreProjected = "leaderboard-match-team-module--teamScoreProjected--b17b2";