import PropTypes from "prop-types"
import React from "react"

import * as styles from "./leaderboard-match-team.module.css"

const LeaderboardMatchTeam = ({ rounds, teams, projected }) => {
  return (
    <>
      <table className={styles.matchTable}>
        <thead>
          <tr>
            <th className="cell-head" style={{ width: "45%" }}>
              <span style={{ fontWeight: "bold" }}>{teams[0].shortName}</span>
              <span className={styles.teamScoreHeader}>{teams[0].score}</span>
            </th>
            <th className="cell-center cell-head" style={{ width: "5%" }}>
              &nbsp;
            </th>
            <th
              className="cell-head"
              style={{
                width: "45%",
                textAlign: "right",
                paddingRight: "0.96667rem",
                paddingLeft: "0.16667rem",
              }}
            >
              <span style={{ fontWeight: "bold" }}>{teams[1].shortName}</span>
              <span className={styles.teamScoreHeader}>{teams[1].score}</span>
            </th>
          </tr>
        </thead>

        {projected && (
          <tbody>
            <tr className={styles.teamScoreProjected}>
              <td style={{ width: "45%" }}>
                <span className={styles.teamScoreHeaderProjected}>
                  {teams[0].projectedScore}
                </span>
              </td>
              <td className="cell-center cell-head" style={{ width: "5%" }}>
                <span>projected</span>
              </td>
              <td
                style={{
                  width: "45%",
                  textAlign: "right",
                  paddingRight: "0.96667rem",
                  paddingLeft: "0.16667rem",
                }}
              >
                <span className={styles.teamScoreHeaderProjected}>
                  {teams[1].projectedScore}
                </span>
              </td>
            </tr>
          </tbody>
        )}

        {rounds.map((round) => (
          <>
            <tbody>
              <tr>
                <td className="cell-row-th" colSpan="3">
                  {round.roundFormat}
                </td>
              </tr>
            </tbody>
            <tbody style={{ fontSize: "90%" }}>
              {round.matches.map((match) => (
                <tr key={match.matchNum}>
                  <td>
                    <div
                      className={
                        match.teams[0].matchLeader
                          ? styles.matchWrapLeftActive
                          : styles.matchWrap
                      }
                    >
                      <div
                        className={
                          match.teams[0].matchWinner ? styles.matchWinner : ""
                        }
                      >
                        {match.teams[0].players.map((p) => (
                          <div key={p.pid}>{p.lastName}</div>
                        ))}
                      </div>
                      {match.teams[0].matchLeader && (
                        <span className={styles.matchScoreLeft}>
                          {match.thru === "F"
                            ? match.teams[0].finalMatchScore
                                .replace("and", "&")
                                .replace(/ /g, "")
                            : match.teams[0].currentMatchScore}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="cell-center" style={{ lineHeight: "1.2" }}>
                    {match.thru === "0" || match.thru === 0
                      ? match.teeTime
                      : `${
                          match.teams[0].currentMatchScore === "Tied" ? "" : ""
                        } ${match.thru === "F" ? "F" : "thru " + match.thru}`}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      paddingRight: "0.96667rem",
                      paddingLeft: "0.16667rem",
                    }}
                  >
                    <div
                      className={
                        match.teams[1].matchLeader
                          ? styles.matchWrapRightActive
                          : styles.matchWrap
                      }
                    >
                      {match.teams[1].matchLeader && (
                        <span className={styles.matchScoreRight}>
                          {match.thru === "F"
                            ? match.teams[1].finalMatchScore
                                .replace("and", "&")
                                .replace(/ /g, "")
                            : match.teams[1].currentMatchScore}
                        </span>
                      )}
                      <div
                        className={
                          match.teams[1].matchWinner ? styles.matchWinner : ""
                        }
                        style={{ marginLeft: "auto" }}
                      >
                        {match.teams[1].players.map((p) => (
                          <div key={p.pid}>{p.lastName}</div>
                        ))}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        ))}
      </table>
    </>
  )
}

LeaderboardMatchTeam.propTypes = {
  rounds: PropTypes.array,
  teams: PropTypes.array,
  started: PropTypes.bool,
  projected: PropTypes.bool,
}

LeaderboardMatchTeam.defaultProps = {
  rounds: [],
  teams: [],
  started: false,
  projected: false,
}

export default LeaderboardMatchTeam
