export const fetchData = async (tid) => {
  const query = process.env.GATSBY_API_LOCAL
    ? `https://cors-anywhere.herokuapp.com/https://www.golfchannel.com/api/v2/events/${tid}/leaderboard`
    : `/api/pga/leaderboard/${tid}`

  const response = await fetch(query)
  const data = await response.json()

  const currentRound = data.result.roundStatus.currentRound

  const allRounds = data.result.matchPlays.map((match) => ({
    roundNumber: match.roundNumber,
    matchNum: match.matchNumber,
    thru: match.hole === 99 ? "F" : match.hole,
    teeTime: match.hole === 0 ? match.score : "",
    teams: [
      {
        matchLeader: match.advantage === 1,
        matchWinner: match.hole === 99 && match.advantage === 1,
        finalMatchScore: match.score,
        currentMatchScore: match.score,
        players: [
          {
            pid: match.golfer1.golferId,
            lastName: match.golfer1.lastName,
          },
        ].concat(
          match.golfer1B
            ? [
                {
                  pid: match.golfer1B.golferId,
                  lastName: match.golfer1B.lastName,
                },
              ]
            : []
        ),
      },
      {
        matchLeader: match.advantage === 2,
        matchWinner: match.hole === 99 && match.advantage === 2,
        finalMatchScore: match.score,
        currentMatchScore: match.score,
        players: [
          {
            pid: match.golfer2.golferId,
            lastName: match.golfer2.lastName,
          },
        ].concat(
          match.golfer2B
            ? [
                {
                  pid: match.golfer2B.golferId,
                  lastName: match.golfer2B.lastName,
                },
              ]
            : []
        ),
      },
    ],
  }))

  const rounds = [
    {
      roundNumber: 1,
      roundFormat: "Day 1 Foursomes",
      matches: allRounds.filter((r) => r.roundNumber === 1),
    },
    {
      roundNumber: 2,
      roundFormat: "Day 1 Four ball",
      matches: allRounds.filter((r) => r.roundNumber === 2),
    },
    {
      roundNumber: 3,
      roundFormat: "Day 2 Four ball",
      matches: allRounds.filter((r) => r.roundNumber === 3),
    },
    {
      roundNumber: 4,
      roundFormat: "Day 2 Four ball",
      matches: allRounds.filter((r) => r.roundNumber === 4),
    },
    {
      roundNumber: 5,
      roundFormat: "Day 3 Singles",
      matches: allRounds.filter((r) => r.roundNumber === 5),
    },
  ]

  const activeRounds = rounds.filter((r) => r.roundNumber <= currentRound)

  //   const currentRound = data.result.matchPlays.find(
  //     (r) => r.roundNumber === currentRound
  //   )

  const teamScores = data.result.matchPlayTeams.map((team) => ({
    shortName: team.teamName,
    score: team.teamScore,
  }))

  //   const showProjected =
  //     data.currentRound === data.totalRnds &&
  //     data.currentRoundState !== "Official"

  return {
    started: true,
    rounds: activeRounds.reverse(),
    currentRound: currentRound,
    teamScores: teamScores,
    roundState: data.currentRoundState,
    showProjected: false,
  }
}
