import PropTypes from "prop-types"
import React, { useReducer, useEffect } from "react"

import LeaderboardMatchTeam from "../leaderboard-match-team/leaderboard-match-team"

import PageLoading from "../layout/page-loading"
import TournamentHeader from "../tournament/tournament-header"

import { fetchData } from "../../util/golfchannel.matchplay-team"

const initialState = {
  started: false,
  rounds: [],
  teamScores: [],
  roundState: "",
  currentRound: "0",
  showProjected: false,
  loading: false,
}

function reducer(state, action) {
  switch (action.type) {
    case "updateLeaderboard":
      return {
        started: action.payload.started,
        rounds: action.payload.rounds,
        teamScores: action.payload.teamScores,
        loading: false,
        currentRound: action.payload.currentRound,
        roundState: action.payload.roundState,
        showProjected: action.payload.showProjected,
      }
    case "showLoader":
      return { ...state, loading: true }
    default:
      return state
  }
}

const PresidentsCup = ({ name, course, location, tid }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: "showLoader" })
    fetchData(tid).then((data) =>
      dispatch({
        type: "updateLeaderboard",
        payload: data,
      })
    )
  }, [tid])

  useEffect(() => {
    const checkVisibilityState = () => {
      if (document.visibilityState === "visible") {
        dispatch({ type: "showLoader" })
        fetchData(tid).then((data) =>
          dispatch({
            type: "updateLeaderboard",
            payload: data,
          })
        )
      }
    }
    document.addEventListener("visibilitychange", checkVisibilityState)
    return () => {
      document.removeEventListener("visibilitychange", checkVisibilityState)
    }
  }, [tid])

  if (state.rounds.length === 0) {
    return <PageLoading />
  }
  return (
    <div>
      <div className="page-inner-padding" style={{ marginBottom: "2em" }}>
        <TournamentHeader name={name} course={course} location={location} />
      </div>

      <section>
        <LeaderboardMatchTeam
          rounds={state.rounds}
          started={state.started}
          teams={state.teamScores}
          projected={state.showProjected}
        />
      </section>
    </div>
  )
}

PresidentsCup.propTypes = {
  name: PropTypes.string,
  location: PropTypes.string,
  course: PropTypes.string,
  tid: PropTypes.string,
}

PresidentsCup.defaultProps = {
  name: "Ryder Cup",
  course: "Whistling Straights",
  location: "WI, USA",
  tid: "18543",
}

export default PresidentsCup
